const prefix = process.env.REACT_APP_API_BASE_URL || 'https://richmond-admin.advent.zone' || 'http://localhost:8000'

export const CLIENT_URL = process.env.REACT_APP_CLIENT_BASE_URL || 'https://richmond-player.advent.zone' || 'http://localhost:8080'

export const LOGIN = () => {
    return prefix + '/api/auth/obtain-token'
}

export const VERIFY_TOKEN = () => {
    return prefix + '/api/auth/refresh-token'
}

export const LOAD_SCREENS = () => {
    return prefix + '/api/cms/screens'
}

export const UPDATE_SCREEN = () => {
    return prefix + '/api/cms/screens'
}

export const LOAD_GROUPS = () => {
    return prefix + '/api/cms/groups'
}

export const LOAD_TEMPLATES = () => {
    return prefix + '/api/cms/templates'
}

export const LOAD_ASSETS = () => {
    return prefix + '/api/cms/media-library'
}

export const DELETE_ASSET = () => {
    return prefix + '/api/cms/media-library'
}

export const ADD_APP = () => {
    return prefix + '/api/cms/apps'
}

export const DELETE_APP = () => {
    return prefix + '/api/cms/apps'
}

export const PATCH_APP = () => {
    return prefix + '/api/cms/apps'
}

export const UPLOAD_IMAGE = () => {
    return prefix + '/api/cms/media-library'
}
