import React, { Component } from 'react';
import { Layout, Menu, Button, Spin} from 'antd';
import {connect} from 'react-redux'
import _ from 'lodash'
import { PlaySquareOutlined, FileOutlined, LogoutOutlined, FolderOutlined, FolderOpenOutlined, PictureOutlined} from '@ant-design/icons';
import axios from 'axios'
import logo from './../assets/ARE_logo.png'
import headerBackground from '../assets/ARE_header.png'
import { Route } from 'react-router-dom';
import ContentObjectList from './ContentObjectList';
import ContentObjectSortableList from './ContentObjectSortableList';
import ContentObjectEditor from './ContentObjectEditor';
import FileManager from './FileManager'
import '../assets/fonts.css'
import * as actionSession from '../store/actions/session'
import * as actionApps from '../store/actions/apps'
import * as actionAssets from '../store/actions/assets'
import * as EP from '../data/paths'

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;




class Editor extends Component {

    constructor(props) {
        super(props);
        const path = props.route.location.pathname || ''
        const pathArr = path.split('/').slice(1,path.length)
        const subs = pathArr.length > 1 ? pathArr.slice(0, pathArr.length) : []
        for(let a = 1; a<subs.length; a++){
            subs[a] = subs[a-1]+'/'+subs[a]
        }

        this.state = {
          openSubs:subs,
          selectedAppPath:null,
          stageApp:null
        };
    }

    componentDidMount() {
       this.props.dispatch(actionApps.loadApps())
       this.props.dispatch(actionAssets.loadAssets())

        axios.get(EP.LOAD_TEMPLATES())
        .then(res => {
            const {data} = res
            data.sort((a, b) => a.name.localeCompare(b.name));
            this.props.dispatch({type:'setTemplates', payload:{templates:data}})
        })
        .catch(error => {
            console.log(error);
        })
    }

    isReady = () => {
        return (this.props && this.props.apps != null && this.props.templates != null && this.props.assets)
    }

    getAppsByTemplateName = (templateName) => {
        const {apps, templates} = this.props;
        // find template id by name
        const templateID = templates.find(template => (template.name === templateName)).id

        // filter groups from other apps
        const filteredGroups = _.cloneDeep(apps)
        let currentGroup;
        let currentApp;
        for(let a = 0; a<filteredGroups.length; a++){
            currentGroup = filteredGroups[a]
            // remove non relevant apps
            for(let b = 0; b<currentGroup.apps.length; b++){
                currentApp = currentGroup.apps[b]
                if(currentApp.template !== templateID){
                    currentGroup.apps.splice(b,1);
                    b--;
                }
            }
        }
        return filteredGroups;
    }

    getAppByID = (appID) => {
        const {apps} = this.props;
        let currentGroup;
        let currentApp;
        for(let a = 0; a<apps.length; a++){
            currentGroup = apps[a]
            for(let b = 0; b<currentGroup.apps.length; b++){
                currentApp = currentGroup.apps[b]
                if(String(currentApp.id) === String(appID)){
                    return currentApp
                }
            }
        }

        return null
    }



    getScreenObjByPath = ({groupName, buildingName, screenID}) => {
        const group = this.props.apps.find((item) => item.name === groupName);
        const subgroup = group.subgroups.find((item) => item.name === buildingName);
        const screen = _.cloneDeep(subgroup.screens.find((item) => item.name === screenID));

        return screen;
    }

    getActiveMenuBullet = () => {
        let path = this.props.route.location.pathname
        return path.substr(1, path.length)
    }

    onMenuOpenChange = (openSubs) => {
        this.setState({openSubs})
    }

    onMenuClick = ({item}) => {
        const {path} = item.props
        const pathStr = '/' + path.join('/')
        this.props.route.history.push(pathStr)
    }

    onLogoutButton = () => {
        this.props.dispatch(actionSession.logout())
    };

    render() {
        const name = this.props.session.username;
        const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)

        return (
            <Layout>
                <Header
                    style={{
                        backgroundImage: `url(${headerBackground})`,
                        backgroundSize: 'contain',
                        padding: "0 50px 0 15px",
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'space-between'
                    }}
                    className="header">
                <div style = {{color:'white', marginLeft:'36px', fontSize:'1.6rem'}}>
                <strong>Richmond SPIDERS</strong>
                </div>
                </Header>
                <Content style={{ padding: '20px 50px' }}>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <h1>{`Welcome ${nameCapitalized}`}</h1>
                    <Button onClick = {this.onLogoutButton}><LogoutOutlined />{`Log out`}</Button>
                </div>

                <Layout style={{ padding: '24px 0', background: '#fff' }}>
                    <Sider width={300} style={{ background: '#fff' }}>
                        {this.renderMenu()}
                    </Sider>
                    <Content style={{ padding: '0 24px', minHeight: 500 }}>
                        {this.renderContent()}
                    </Content>
                </Layout>
                </Content>
                <Footer style={{ textAlign: 'center', fontFamily: 'Avenir Next LT Pro' }}>Richmond SPIDERS - Sedna Portal</Footer>
                {!this.isReady() && this.renderLoadingOverlay()}
            </Layout>
        );
    }

    renderLoadingOverlay = () => {
        return(
            <div style = {{position:'fixed', left:0, top:0, bottom:0, right:0, backgroundColor:'white', opacity: 0.7, }}>
                <div style = {{textAlign:'center', padding:'240px'}}>
                    <Spin size="large" tip='Loading...'/>
                </div>
            </div>
        )
    }

    renderMenu = () => {
        const templates = this.props.templates || []
        const screenGroups = this.props.apps || []
        let key;

        return(
            <Menu
                mode="inline"
                style={{ height: '100%' }}
                onOpenChange = {this.onMenuOpenChange}
                onClick = {this.onMenuClick}
                openKeys = {this.state.openSubs}
                selectedKeys = {this.getActiveMenuBullet()}
            >
                <SubMenu
                    key={'templates'}
                    title={
                        <span>
                            {this.state.openSubs.indexOf('templates') > -1 ? <FolderOpenOutlined/> : <FolderOutlined/>}
                            {`Templates (${templates.length})`}
                        </span>
                    }>
                    {templates.map((template, a) =>
                        <Menu.Item key={"templates/" + template.name} template_id = {template.id} path={['templates', template.name]}><FileOutlined />{template.name}</Menu.Item>
                    )}
                </SubMenu>
                <SubMenu
                    key={'screens'}
                    title={
                        <span>
                            {this.state.openSubs.indexOf('screens') > -1 ? <FolderOpenOutlined/> : <FolderOutlined/>}
                            {`Screens (${screenGroups.length})`}
                        </span>
                    }>
                    {screenGroups.map((screenGroup, a) =>
                        <SubMenu
                            key={`screens/${screenGroup.name}`}
                            title={
                                <span>
                                    {this.state.openSubs.indexOf(`screens/${screenGroup.name}`) > -1 ? <FolderOpenOutlined/> : <FolderOutlined/>}
                                    {`${screenGroup.name} (${screenGroup.subgroups.length})`}
                                </span>
                            }>
                            {
                                screenGroup.subgroups.map((building, b) =>
                                <SubMenu
                                key={`screens/${screenGroup.name}/${building.name}`}
                                title={
                                    <span>
                                        {this.state.openSubs.indexOf(`screens/${screenGroup.name}/${building.name}`) > -1 ? <FolderOpenOutlined/> : <FolderOutlined/>}
                                        {`${building.name} (${building.screens.length})`}
                                    </span>
                                }>
                                {
                                    building.screens.map((screen, c) => {
                                        key = `screens/${screenGroup.name}/${building.name}/${screen.name}`
                                        return(<Menu.Item zzz = {key} key={key} path={['screens', screenGroup.name, building.name, screen.name]}><PlaySquareOutlined />{screen.name}</Menu.Item>
                                    )})}
                                </SubMenu>
                         )}
                        </SubMenu>
                    )}
                </SubMenu>
                <Menu.Item key='assets' path={['assets']}>
                    <PictureOutlined />Assets
                </Menu.Item>
            </Menu>
        )
    }

    renderContent = () => {
        if(!this.isReady()){
            return null
        }

        const renderContentObjectEditor = (route) =>
                                <ContentObjectEditor
                                    dispatch = {this.props.dispatch}
                                    apps = {this.props.apps}
                                    route={route}
                                    app={this.getAppByID(route.match.params.contentObjectID)}
                                    templates={this.props.templates}/>

        return(
            <div>
                <Route exact path='/assets' render = {(route) => <FileManager/>}/>
                <Route exact path='/templates/:templateName' render =
                    {(route) => <ContentObjectList
                                    dragable = {false}
                                    dispatch = {this.props.dispatch}
                                    key = {route.match.params.templateName}
                                    route={route}
                                    templates = {this.props.templates}
                                    list={this.getAppsByTemplateName(route.match.params.templateName)}
                                />}
                />
                <Route exact path='/templates/:templateName/:contentObjectID' render =
                    {(route) => renderContentObjectEditor(route)}/>
                <Route exact path='/templates/:templateName/:contentObjectID/:tab' render =
                    {(route) => renderContentObjectEditor(route)}/>
                <Route exact path='/screens/:groupName/:buildingName/:screenID' render =
                    {(route) => <ContentObjectSortableList
                        key={route.location.pathname}
                        dispatch = {this.props.dispatch}
                        route={route}
                        screen = {this.getScreenObjByPath(route.match.params)}
                        apps = {this.props.apps}
                        templates={this.props.templates}/>
                    }/>
                <Route exact path='/screens/:groupName/:buildingName/:screenID/:contentObjectID/:tab' render =
                    {(route) => renderContentObjectEditor(route)}/>
            </div>
        )
    }
}


export default connect(state => {
    return { screens: state.screens, apps: state.apps, templates:state.templates, session:state.session, assets:state.assets};
  })(Editor);
